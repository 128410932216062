import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AbstractServerInteractionService, combineHeadersOptions,
  commonErrorHandlingDisabled
} from '../abstract/abstract.server.interaction.service';
import { Observable, catchError, of } from 'rxjs';
import { Router } from '@angular/router';

import { DirectDepositViewModel } from './interfaces';
import { AppService } from '../../../../app.service';

@Injectable()
export class DirectDepositService extends AbstractServerInteractionService {
  protected get url(): string {
    return this.appService.getApiUrls().directDeposit;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient,
              protected router: Router) {
    super();
  }

  public getRecruitPayroll(): Observable<DirectDepositViewModel | any> {
    return this.http.get(this.url, {headers: combineHeadersOptions(commonErrorHandlingDisabled)})
      .pipe(catchError(() => of({ paymentElections: [] })));
  }

  public submitElections(data: DirectDepositViewModel): Observable<any> {
    return this.http.post(this.url, data, {headers: combineHeadersOptions(commonErrorHandlingDisabled)})
     .pipe(catchError((response: Response) => of(response.json())));
  }
}
