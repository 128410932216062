import { Action } from '@ngrx/store';
import { type } from '../../../util';
import { IFormInputChangePayload } from '../../abstract.components/abstract.form.component';
import { IElection } from './direct-deposit.reducer';

export const ActionTypes = {
  ELECTIONS_FETCH: type('[Elections list] fetch'),
  ELECTIONS_LIST_UPDATE: type('[Elections list] update'),
  ELECTION_PRE_POPULATE: type('[Election form] pre populate'),
  ELECTION_INPUT_UPDATE: type('[Election form] input change'),
  ELECTION_ADD: type('[Election form] add'),
  ELECTION_UPDATE: type('[Election form] update'),
  ELECTION_CLEAR: type('[Election form] clear'),
  ELECTION_SORTING: type('[Election list] elections sorting'),
  ELECTION_DELETE_DIALOG: type('[Election list] delete dialog election'),
  ELECTION_DELETE_CONFIRM: type('[Election list] delete election confirm'),
  ELECTION_DELETE_CANCEL: type('[Election list] delete election cancel'),
  ELECTIONS_SUBMIT: type('[Elections list] submit all elections'),
  SET_ELECTION_ERROR_MESSAGE: type('[Election] set error message'),
  ELECTIONS_RESET: type('[Elections list] set elections to null')
};

export class FetchElections implements Action {
  public type = ActionTypes.ELECTIONS_FETCH;

  constructor(public payload?: any) {}
}

export class UpdateElectionsList implements Action {
  public type = ActionTypes.ELECTIONS_LIST_UPDATE;

  constructor(public payload: IElection[]) {}
}

export class ElectionPrepopulate implements Action {
  public type = ActionTypes.ELECTION_PRE_POPULATE;

  constructor(public payload: number) {}
}

export class ElectionInputUpdate implements Action {
  public type = ActionTypes.ELECTION_INPUT_UPDATE;

  constructor(public payload: IFormInputChangePayload) {}
}

export class ElectionUpdate implements Action {
  public type = ActionTypes.ELECTION_UPDATE;

  constructor(public payload: IElection) {}
}

export class AddElection implements Action {
  public type = ActionTypes.ELECTION_ADD;

  constructor(public payload: IElection) {}
}

export class ClearElectionFormData implements Action {
  public type = ActionTypes.ELECTION_CLEAR;

  constructor(public payload?: any) {}
}

export class ElectionSorting implements Action {
  public type = ActionTypes.ELECTION_SORTING;

  constructor(public payload: {
    from: number,
    to: number
  }) {}
}

export class ElectionDeleteDialog implements Action {
  public type = ActionTypes.ELECTION_DELETE_DIALOG;

  constructor(public payload: string) {}
}

export class ElectionDeleteConfirm implements Action {
  public type = ActionTypes.ELECTION_DELETE_CONFIRM;

  constructor(public payload?: any) {}
}

export class ElectionDeleteCancel implements Action {
  public type = ActionTypes.ELECTION_DELETE_CANCEL;

  constructor(public payload?: any) {}
}

export class ElectionsSubmit implements Action {
  public type = ActionTypes.ELECTIONS_SUBMIT;

  constructor(public payload?: any) {}
}

export class SetElectionErrorMessage implements Action {
  public type = ActionTypes.SET_ELECTION_ERROR_MESSAGE;

  constructor(public payload: string | null = null) {}
}

export class ResetElections implements Action {
  public type = ActionTypes.ELECTIONS_RESET;

  constructor(public payload?: any) {}
}

export type TypeAction =
  FetchElections |
  UpdateElectionsList |
  ElectionPrepopulate |
  ElectionInputUpdate |
  ElectionUpdate |
  AddElection |
  ClearElectionFormData |
  ElectionSorting |
  ElectionDeleteDialog |
  ElectionDeleteConfirm |
  ElectionDeleteCancel |
  ElectionsSubmit |
  SetElectionErrorMessage |
  ResetElections;
