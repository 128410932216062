import { createSelector } from '@ngrx/store';
import { ActionTypes, TypeAction } from './direct-deposit.actions';
import { IRootState } from '../../../store';

export enum AccountTypeEnum {
  Checking = 0,
  Savings = 1
}

export enum DistributionTypeEnum {
  Amount = 0,
  Percent = 1,
  Remaining = 2
}

export interface IBankAccount {
  bankName: string;
  accountNickName: string;
  accountNumber: string;
}

export interface IUSBankAccount extends IBankAccount {
  routingNumber: string;
}

export interface ICanadianBankAccount extends IBankAccount {
  branchId: string;
  institutionNumber: string;
}

export interface IElection extends IUSBankAccount, ICanadianBankAccount {
  bankAccountType: string;
  distributionType: string;
  distributionValue: string;
}

export interface IRadio {
  text: string;
  value: number;
  disabled?: boolean;
}

export interface IState {
  electionFormData: IElection;
  bankAccountTypeRadioValues: IRadio[];
  distributionTypeRadioValues: IRadio[];
  elections: IElection[] | null;
  electionToUpdate: string | null;
  electionToDelete: string | null;
  errorMessage: string | null;
}

const initialState: IState = {
  electionFormData: {
    bankName: '',
    accountNickName: '',
    routingNumber: '',
    branchId: '',
    institutionNumber: '',
    accountNumber: '',
    bankAccountType: String(AccountTypeEnum.Checking),
    distributionType: String(DistributionTypeEnum.Remaining),
    distributionValue: ''
  },
  bankAccountTypeRadioValues: [
    { text: 'Checking', value: AccountTypeEnum.Checking },
    { text: 'Savings', value: AccountTypeEnum.Savings }
  ],
  distributionTypeRadioValues: [
    { text: 'Amount', value: DistributionTypeEnum.Amount, disabled: true },
    { text: 'Percent', value: DistributionTypeEnum.Percent, disabled: true },
    { text: 'Remaining', value: DistributionTypeEnum.Remaining, disabled: true },
  ],
  elections: null,
  electionToUpdate: null,
  electionToDelete: null,
  errorMessage: null
};

export function directDepositReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.ELECTIONS_LIST_UPDATE: {
      return Object.assign({}, state, { elections: payload });
    }
    case ActionTypes.ELECTION_PRE_POPULATE: {
      return Object.assign({}, state, {
        electionToUpdate: payload,
        electionFormData: state.elections[payload]
      });
    }
    case ActionTypes.ELECTION_INPUT_UPDATE: {
      const { electionFormData } = state;

      const result = Object.assign({}, electionFormData, {
        [payload.fieldId]: payload.value
      });

      return Object.assign({}, state, { electionFormData: result });
    }
    case ActionTypes.ELECTION_ADD: {
      return Object.assign({}, state, { elections: [...state.elections, payload] });
    }
    case ActionTypes.ELECTION_UPDATE: {
      const elections = [...state.elections];

      elections[state.electionToUpdate] = payload;

      return Object.assign({}, state, { elections });
    }
    case ActionTypes.ELECTION_CLEAR: {
      return Object.assign({}, state, {
        electionFormData: initialState.electionFormData,
        electionToUpdate: null
      });
    }
    case ActionTypes.ELECTION_SORTING: {
      const { elections } = state;
      const { from, to } = payload;
      const result = [...elections];

      result.splice(to, 0, result.splice(from, 1)[0]);

      return Object.assign({}, state, { elections: result });
    }
    case ActionTypes.ELECTION_DELETE_DIALOG: {
      return Object.assign({}, state, { electionToDelete: payload });
    }
    case ActionTypes.ELECTION_DELETE_CONFIRM: {
      const { elections, electionToDelete } = state;
      const result = [...elections];

      result.splice(+electionToDelete, 1);

      return Object.assign({}, state, { elections: result, electionToDelete: null });
    }
    case ActionTypes.ELECTION_DELETE_CANCEL: {
      return Object.assign({}, state, { electionToDelete: null });
    }
    case ActionTypes.SET_ELECTION_ERROR_MESSAGE: {
      return Object.assign({}, state, { errorMessage: payload });
    }
    case ActionTypes.ELECTIONS_RESET: {
      return Object.assign({}, state, { elections: null });
    }
    default: {
      return state;
    }
  }
}

export const getElectionFormData = createSelector(
  (rootState: IRootState) => {
    return rootState.directDepositTask;
  },
  (state: IState): IElection => state.electionFormData
);

export const getElections = createSelector(
  (rootState: IRootState) => {
    return rootState.directDepositTask;
  },
  (state: IState): IElection[] => state.elections
);

export const getFilteredElections = createSelector(
  (rootState: IRootState) => {
    return rootState.directDepositTask;
  },
  (state: IState): IElection[] => state.elections
    .filter((election, index) => String(index) !== state.electionToUpdate)
);

export const getUSBankAccounts = createSelector(
  (rootState: IRootState) => {
    return rootState.directDepositTask;
  },
  (state: IState): IUSBankAccount[] => state.elections
    .filter((electionAccount, index, bankAccounts) =>
      index === bankAccounts.findIndex((account) => {
        return account.bankName === electionAccount.bankName
          && account.accountNickName === electionAccount.accountNickName
          && account.routingNumber === electionAccount.routingNumber
          && account.accountNumber === electionAccount.accountNumber;
      }) && String(index) !== state.electionToUpdate
    )
    .map(({ bankName, accountNickName, routingNumber, accountNumber }) => {
      return {
        bankName,
        accountNickName,
        routingNumber,
        accountNumber
      };
    })
);

export const getCanadianBankAccounts = createSelector(
  (rootState: IRootState) => {
    return rootState.directDepositTask;
  },
  (state: IState): ICanadianBankAccount[] => state.elections
    .filter((electionAccount, index, bankAccounts) =>
      index === bankAccounts.findIndex((account) => {
        return account.bankName === electionAccount.bankName
          && account.accountNickName === electionAccount.accountNickName
          && account.branchId === electionAccount.branchId
          && account.institutionNumber === electionAccount.institutionNumber
          && account.accountNumber === electionAccount.accountNumber;
      }) && String(index) !== state.electionToUpdate
    )
    .map(({ bankName, accountNickName, branchId, institutionNumber, accountNumber }) => {
      return {
        bankName,
        accountNickName,
        branchId,
        institutionNumber,
        accountNumber
      };
    })
);

export const getBankAccountTypes = createSelector(
  (rootState: IRootState) => {
    return rootState.directDepositTask;
  },
  (state: IState): IRadio[] => state.bankAccountTypeRadioValues
);

export const getDistributionTypes = createSelector(
  (rootState: IRootState) => {
    return rootState.directDepositTask;
  },
  (state: IState): IRadio[] => state.distributionTypeRadioValues
);

export const getElectionToDelete = createSelector(
  (rootState: IRootState) => rootState.directDepositTask,
  (state: IState): string | null => state.electionToDelete
);

export const getElectionErrorMessage = createSelector(
  (rootState: IRootState) => rootState.directDepositTask,
  (state: IState): string | null => state.errorMessage
);
